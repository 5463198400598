import React, {useState, setState} from 'react';
import '../style.css'


function RegistrationForm() {


    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [age, setAge] = useState(null);
    const [university, setUniversity] = useState(null);
    const [team_size, setSize] = useState(null);
    const [team_1, setTeam1] = useState(null);
    const [team_2, setTeam2] = useState(null);
    const [team_3, setTeam3] = useState(null);


    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "firstName"){
            setFirstName(value);
        }
        if(id === "lastName"){
            setLastName(value);
        }
        if(id === "email"){
            setEmail(value);
        }
       
        if(id === "age"){
            setAge(value);
        }
       
        if(id === "university"){
            setUniversity(value);
        }
       
        if(id === "team_size"){
            setSize(value);
        }
       
        if(id === "team_1"){
            setTeam1(value);
        }
       
        if(id === "team2"){
            setTeam2(value);
        }
       
        if(id === "team3"){
            setTeam3(value);
        }
       

    }

    const handleSubmit  = () => {
        console.log(firstName,lastName,email,age,university,team_size,team_1,team_2,team_3);
    }





    return(
      <div className="form">
          <div className="form-body">
              <div className="username">
                  <label className="form__label" for="firstName">First Name </label>
                  <input className="form__input" type="text" id="firstName" value={firstName} onChange = {(e) => handleInputChange(e)} placeholder="First Name"/>
              </div>
              <div className="lastname">
                  <label className="form__label" for="lastName">Last Name </label>
                  <input  type="text" name="" id="lastName"  className="form__input" value={lastName} onChange = {(e) => handleInputChange(e)} placeholder="LastName"/>
              </div>
              <div className="email">
                  <label className="form__label" for="email">Email </label>
                  <input  type="email" id="email" className="form__input" value={email} onChange = {(e) => handleInputChange(e)} placeholder="Email"/>
              </div>
              <div className="age">
                  <label className="form__label" for="Age">Age </label>
                  <input className="form__input" type="number"  id="age" value={age} onChange = {(e) => handleInputChange(e)} placeholder="Age"/>
              </div>
              <div className="university">
                  <label className="form__label" for="university">University </label>
                  <input className="form__input" type="text" id="university" value={university} onChange = {(e) => handleInputChange(e)} placeholder="University"/>
              </div>
              <div className="team_size">
                  <label className="form__label" for="team_size">Team Size </label>
                  <input className="form__input" type="text" id="team_size" value={team_size} onChange = {(e) => handleInputChange(e)} placeholder="Team Size"/>
              </div>
              <div className="team_1">
                  <label className="form__label" for="team_1">Member 1 </label>
                  <input className="form__input" type="text" id="team_1" value={team_1} onChange = {(e) => handleInputChange(e)} placeholder="Member 1"/>
              </div>
              <div className="team_2">
                  <label className="form__label" for="team_2">Member 2 </label>
                  <input className="form__input" type="text" id="team_2" value={team_2} onChange = {(e) => handleInputChange(e)} placeholder="Member 2"/>
              </div>
              <div className="team_3">
                  <label className="form__label" for="team_3">Member 3 </label>
                  <input className="form__input" type="text" id="team_3" value={team_3} onChange = {(e) => handleInputChange(e)} placeholder="Member 3"/>
              </div>
          </div>
          <div class="footer">
              <button onClick={()=>handleSubmit()} type="submit" class="btn">Register</button>
          </div>
      </div>      
    )       
}
export default RegistrationForm;